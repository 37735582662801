import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { notification, Select } from 'antd';
import Table from './Table';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { Main, Section } from '../../../components/content';
import { notificationMessages } from '../../../helpers/notificationMessages';
import { jwtDecode } from 'jwt-decode';
import { getGroceryPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { UpdateFormAllSettings } from './update-form/update-form-all-settings/UpdateFormAllSettings';
import { useTranslation } from 'react-i18next';

const options = [5, 10, 20, 50, 100];

const Venues = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [list, setList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [venueData, setVenueData] = useState({});
  const [allDisciplines, setAllDisciplines] = useState([]);
  const [allAttributes, setAllAttributes] = useState([]);
  const [selectedDisciplinesIds, setSelectedDisciplinesIds] = useState([]);
  const [selectedVenueAttributes, setSelectedVenueAttributes] = useState([]);
  const [venueDisciplines, setVenueDisciplines] = useState([]);
  const [updateFormFields, setUpdateFormFields] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // LOADER PROTECTION
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [loadingVenue, setLoadingVenue] = useState(false);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [filterStatus, setFilterStatus] = useState(null);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setRefetch((prevState) => !prevState);
  };

  const getTableData = useCallback(async () => {
    const { token } = user;

    setLoading(true);
    try {
      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.venues}/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log('res', res);
          setList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [user, currentPage, limitPage, search, filterStatus]);

  // FETCH DATA FOR `TABLE`
  useEffect(() => {
    getTableData();
  }, [getTableData, refetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage]);

  const getAllDisciplines = useCallback(
    (props) => {
      const { token } = user;

      try {
        const response = axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.disciplines}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            return res;
          })
          .catch((err) => {});

        return response;
      } catch (err) {}
    },
    [user]
  );

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(getAllDisciplines());
      }, 200);
    }).then((res) => {
      if (isMounted) {
        setAllDisciplines(res.data.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const getAllAttributesByDisciplines = useCallback(async () => {
    const venueDataDisciplines = venueDisciplines || [];
    const { token } = user;

    try {
      const res = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.venueAttributesByDisciplines}`, { disciplines: venueDataDisciplines }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });
      return res;
    } catch (err) {
      console.error(err);
      throw err; // Ensure the promise is rejected
    }
  }, [venueDisciplines, user]);

  useEffect(() => {
    let isMounted = true;

    const fetchAttributes = async () => {
      try {
        const res = await new Promise((resolve) => {
          setTimeout(() => {
            resolve(getAllAttributesByDisciplines());
          }, 200);
        });
        if (isMounted) {
          setAllAttributes(res?.data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchAttributes();

    return () => {
      isMounted = false;
    };
  }, [venueDisciplines]);

  // FILTER STATUS
  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setRefetch((prevState) => !prevState);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  const [venueId, setVenueId] = useState({
    id: '',
  });

  const handleToggle = (props) => {
    const { name, state, data } = props;

    switch (name) {
      case 'update':
        setVenueId({ id: data });
        state.onToggle((prevState) => !prevState);
        break;
      default:
        console.error('handleToggle ---- error');
    }
  };

  // Refs
  const updateModalFormRef = useRef(null);

  // State
  const [toggleUpdate, setToggleUpdate] = useState(false);
  // Store
  const [updateVenue, setUpdateVenue] = useState([]);

  // FETCH ROLES
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const { token } = user;

    try {
      axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getRoles}/null/null/null`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setRoles(res.data?.data.Data);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  const handleOnFinish = async (props) => {
    const { name, data } = props;

    const { token } = user;

    switch (name) {
      case 'update':
        setEditBtnLoader(true);
        try {
          const postData = {
            changes: {
              ...data,
              lat: +data?.lat || '',
              lng: +data?.lng || '',
            },
            requestBy: user.id,
          };

          const venueResponse = await axios.put(
            `${SERVER_URL}/${AXIOS_API_CALL.venues}/${venueId.id}`,
            { ...postData },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (venueResponse.request.status === 200) {
            notification.success({
              message: t('venueSentToEditRequestsSucess'),
            });
            setTimeout(() => {
              setEditBtnLoader(false);
            }, 700);
          }
          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleUpdate(false);
          setUpdateVenue([]);
          setVenueId({ id: '' });

          setTimeout(() => {
            updateModalFormRef.current.resetFields();
            window.location.reload();
          }, 750);
        } catch (err) {
          console.log('error', err);
          if (err?.response?.data?.message === 'No changes detected.') {
            console.log(' da da nema detecte4d changes');
            notification.error({
              message: t('noChangesDetected'),
              placement: 'topRight',
            });
          }
          if (err.response.data.message === 'You can not change venue if it is in nyamie request!') {
            notification.error({
              message: t('alreadyInNyamieRequest'),
              placement: 'topRight',
            });
          }
          setEditBtnLoader(false);
        }
        break;

      default:
        console.error('handleOnFinish ---- error');
    }
  };

  // READ - VENUE
  useEffect(() => {
    const { token } = user;
    console.log('ovde smo usli');

    if (!!venueId.id) {
      try {
        setLoadingVenue(true);
        axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.getVenueById}/${venueId.id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setVenueData(res.data);
            setVenueDisciplines(res.data.disciplines.map((discipline) => discipline._id));
            setSelectedVenueAttributes(res.data.attributes.map((attribute) => attribute._id));
            setUpdateFormFields([
              {
                name: 'name',
                value: res.data?.name,
              },
              {
                name: 'lat',
                value: res.data?.lat,
              },
              {
                name: 'lng',
                value: res.data?.lng,
              },
              {
                name: 'phones',
                value: res.data?.phones ? res.data.phones : [],
              },
              {
                name: 'workHours',
                value: {
                  monday: res.data?.workHours?.monday,
                  tuesday: res.data?.workHours?.tuesday,
                  wednesday: res.data?.workHours?.wednesday,
                  thursday: res.data?.workHours?.thursday,
                  friday: res.data?.workHours?.friday,
                  saturday: res.data?.workHours?.saturday,
                  sunday: res.data?.workHours?.sunday,
                },
              },
              {
                name: 'disciplines',
                value: res.data.disciplines.map((discipline) => discipline._id) || [],
              },
              {
                name: 'attributes',
                value: res.data.attributes.map((attribute) => attribute._id) || [],
              },
              {
                name: ['seo', 'title'],
                value: res.data?.seo?.title,
              },
              {
                name: ['seoEn', 'title'],
                value: res.data?.seoEn?.title,
              },
              {
                name: ['seo', 'description'],
                value: res.data?.seo?.description,
              },
              {
                name: ['seoEn', 'description'],
                value: res.data?.seoEn?.description,
              },
              { name: 'is_student', value: res.data?.is_student },
              { name: 'isFavourite', value: res.data?.isFavourite },
            ]);
            setTimeout(() => {
              setLoadingVenue(false);
            }, 700);
          })
          .catch((err) => console.error(err))
          .finally(() => {});
      } catch (err) {
        console.error(err);
        setLoadingVenue(false);
      }
    }
  }, [venueId, setVenueId, setLoadingVenue]);

  // Update
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    data: venueData,
    onData: setUpdateVenue,
    loading: loadingVenue,
  };

  // TABLE
  const tableProps = {
    handleCheckedRows: handleCheckedRows,
  };

  const tableActionsProps = {
    // custom for this table - always changes for next implementation
    handleToggle: handleToggle,
    updateActions: updateProps,
    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter,
  };

  // Table Pagination
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: list?.pagesCount,
    totalCount: list?.totalCount,
  };

  // const updateFormFields = [
  //   {
  //     name: 'name',
  //     value: venueData?.name,
  //   },
  //   {
  //     name: 'lat',
  //     value: venueData?.lat,
  //   },
  //   {
  //     name: 'lng',
  //     value: venueData?.lng,
  //   },
  //   {
  //     name: 'phones',
  //     value: venueData?.phones ? venueData.phones : [],
  //   },
  //   {
  //     name: 'workHours',
  //     value: {
  //       monday: venueData?.workHours?.monday,
  //       tuesday: venueData?.workHours?.tuesday,
  //       wednesday: venueData?.workHours?.wednesday,
  //       thursday: venueData?.workHours?.thursday,
  //       friday: venueData?.workHours?.friday,
  //       saturday: venueData?.workHours?.saturday,
  //       sunday: venueData?.workHours?.sunday,
  //     },
  //   },
  //   {
  //     name: 'disciplines',
  //     value: venueDisciplines || [],
  //   },
  //   {
  //     name: 'attributes',
  //     value: selectedVenueAttributes || [],
  //   },
  //   {
  //     name: ['seo', 'title'],
  //     value: venueData?.seo?.title,
  //   },
  //   {
  //     name: ['seoEn', 'title'],
  //     value: venueData?.seoEn?.title,
  //   },
  //   {
  //     name: ['seo', 'description'],
  //     value: venueData?.seo?.description,
  //   },
  //   {
  //     name: ['seoEn', 'description'],
  //     value: venueData?.seoEn?.description,
  //   },
  //   { name: 'is_student', value: venueData?.is_student },
  //   { name: 'isFavourite', value: venueData?.isFavourite },
  // ];
  // console.log('updateFormFields', updateFormFields);

  const updateFormAllSettingsProps = {
    updateProps,
    updateModalFormRef,
    updateFormFields,
    roles,
    editBtnLoader,
    handleOnFinish,
    setToggleUpdate,
    setSelectedVenueAttributes,
    setSelectedDisciplinesIds,
    selectedDisciplinesIds,
    venueDisciplines,
    setVenueDisciplines,
    selectedVenueAttributes,
  };

  const refetchVenuesData = async () => {
    console.log('usli smo');
    setLoading(true);
    notification.warning({
      message: 'Sync data in progress! Please wait!',
    });
    try {
      const { token } = user;
      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.fetchFitpassCities}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log('res step 1', res);
          if (res.status === 200) {
            notification.success({
              message: 'Cities refetched successfully!',
            });
            setTimeout(() => {
              notification.warning({
                message: 'Step 2, fetching disciplines! Please wait!',
              });
            }, 3000);
          } else {
            notification.error({
              message: 'Error while refetching cities!',
            });
            setLoading(false);
            return;
          }
        })
        .catch((err) => {
          console.log('err step 1', err);
          setLoading(false);
          return;
        });

      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.fetchFitpassDisciplines}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log('res step 2', res);
          if (res.status === 200) {
            notification.success({
              message: 'Disciplines refetched successfully!',
            });
            setTimeout(() => {
              notification.warning({
                message: 'Step 3, fetching activities! Please wait!',
              });
            }, 3000);
          } else {
            notification.error({
              message: 'Error while refetching disciplines!',
            });
            setLoading(false);
            return;
          }
        })
        .catch((err) => {
          console.log('err step 2', err);
          setLoading(false);
          return;
        });

      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.fetchFitpassActivities}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log('res step 3', res);
          if (res.status === 200) {
            notification.success({
              message: 'Activities refetched successfully!',
            });
            setTimeout(() => {
              notification.warning({
                message: 'Step 4, syncing nyami venues ! Please wait!',
              });
            }, 3000);
          } else {
            notification.error({
              message: 'Error while refetching activities!',
            });
            setLoading(false);
            return;
          }
        })
        .catch((err) => {
          console.log('err step 3', err);
          setLoading(false);
          return;
        });

      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.syncNyamiVenues}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log('res step 4', res);
          if (res.status === 200) {
            notification.success({
              message: 'Nyami venues synced successfully!',
            });
            setTimeout(() => {
              notification.warning({
                message: 'Thats it! All data is refetched!',
              });
            }, 3000);
          } else {
            notification.error({
              message: 'Error while sync nyami venues!',
            });
            setLoading(false);
            return;
          }
        })
        .catch((err) => {
          console.log('err step 4', err);
          setLoading(false);
        });
      setLoading(false);
    } catch (err) {
      console.log('error', err);
    }
    //window.location.reload();
  };

  console.log('venue data', venueData);

  return (
    <>
      <h2 className="page-title">{t('venues')}</h2>

      <Section className="section section__wrapper section__employees section__venuesData">
        {/* Main Header */}
        <header className="section__header">
          {!loading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>{t('show')}:</span>
              <SelectCustom options={options} label={`${t('items')}`} limit={limitPage} setLimit={setLimitPage} setRefetch={setRefetch} loading={loading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!loading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
                  </span>
                  <input value={searchForm.query || ''} type="text" className="input" placeholder={t('search')} onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  {t('search')}
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}
          {/* {getGroceryPermissions(groceryPermissions, PERMISSIONS.createAttributes) && (
            <> */}
          <div className="actions">
            {!loading ? (
              <button type="button" onClick={refetchVenuesData} data-cy="create-attribute-btn" className="btn btn-primary pl-4 pr-4">
                <span className="text">{t('refreshVenueData')}</span>
              </button>
            ) : (
              <LoadingPlaceholder style={{ width: '191px', height: '50px' }} />
            )}
          </div>
          {/* </>
          )} */}
        </header>
        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list.Data} {...tableProps} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>
        {/* Update Venue - all settings */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateVenues) && <UpdateFormAllSettings {...updateFormAllSettingsProps} allDisciplines={allDisciplines} allAttributes={allAttributes} />}
      </Section>
    </>
  );
};

export default Venues;
