import React from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { stateUS } from '../../../../helpers/state';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export const CreateForm = ({ createProps, createModalFormRef, handleOnFinish, createFormFields, roles, createBtnLoader, setToggleCreate, countryCodeOptions }) => {
  const { t } = useTranslation();
  return (
    <SidebarModal {...createProps} className="sidebar__modal--right" title={t('createUser')}>
      <Form name="create" ref={createModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'create', data: data })} fields={createFormFields}>
        <Form.Item name="firstName" label={`${t('firstName')}:`} rules={[{ required: true, message: t('pleaseInputName') }]}>
          <Input placeholder={`${t('firstName')}`} />
        </Form.Item>
        <Form.Item name="lastName" label={`${t('lastName')}:`} rules={[{ required: true, message: t('pleaseInputLastName') }]}>
          <Input placeholder={`${t('lastName')}`} />
        </Form.Item>
        <Form.Item name="email" label={`${t('email')}:`} rules={[{ required: true, message: t('pleaseInputEmail') }]}>
          <Input placeholder={`${t('email')}`} />
        </Form.Item>
        <Form.Item name="countryCode" label={`${t('selectCountryCode')}`} rules={[{ required: true, message: t('pleaseInputCountryCode') }]}>
          <Select>
            <Option key={''}>{t('selectCountryCode')}</Option>

            {countryCodeOptions?.map((item) => {
              return (
                <Option key={item._id} value={item._id}>
                  {item.countryName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item name="phone" label={`${t('phone')}:`} rules={[{ required: false, message: t('pleaseInputPhone') }]}>
          <Input placeholder={`${t('phone')}`} />
        </Form.Item>
        <Form.Item name="password" label={`${t('password')}:`} rules={[{ required: true, message: t('pleaseInputPassword') }]}>
          <Input.Password placeholder={`${t('password')}`} />
        </Form.Item>
        <Form.Item name="role" label={`${t('selectRoles')}:`} rules={[{ required: true, message: t('pleaseInputYourRole') }]}>
          <Select>
            <Option key={''}>{`${t('selectRoles')}`}</Option>

            {roles.map((item) => {
              return (
                <Option key={item.name} value={item._id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="createUserId" style={{ display: 'none' }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="createUserType" style={{ display: 'none' }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item className="mt-4 mb-0">
          <Row gutter={16} className="row-direction">
            <Col span={12}>
              <Button type="button" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleCreate(false)}>
                <span>{t('cancel')}</span>
              </Button>
            </Col>
            <Col span={12}>
              {!createBtnLoader ? (
                <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                  <span>{t('addUser')}</span>
                </Button>
              ) : (
                <Button type="button" className="btn btn-primary-outline btn-block">
                  <span>{t('adding')}...</span>
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </SidebarModal>
  );
};
